import React from 'react';
import './team-grid.scss';
import { TeamGridProps } from '../../types/components';

const TeamGrid: React.FC<TeamGridProps> = ({children, sectionClass}) => {
    return (
        <div className={`team-grid ${sectionClass}`}>
            {children}
        </div>
    );
};

export default TeamGrid;