import React from "react"
import { graphql, PageProps } from "gatsby"
import { TeamPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import TeamGrid from "../components/team/team-grid"
import { CardPageProps } from "../types/shared-singulars"
import SliderItem from "../components/shared/slider-item"

const Team: React.FC<PageProps<TeamPageProps>> = ({ data }) => {
  const {
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection,
  } = data?.PAGE_CONTENT?.teamOptions?.teamMembers

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <div className="page--container container--xl">
        <h1 className="w--100">{data?.PAGE_METADATA?.title}</h1>
        <TeamGrid sectionClass="team-grid team-grid__first-section">
          <>
            {firstSection &&
              firstSection.map((member: CardPageProps, index: number) => (
                <div key={index} className="member">
                  <SliderItem
                    parent="team-grid"
                    index={index + 1}
                    uri={member.uri}
                    imgProps={member?.featuredImage?.node}
                    memberTag={member?.singleTeam?.position}
                    title={member.title}
                  />
                </div>
              ))}
          </>
        </TeamGrid>
        <TeamGrid sectionClass="team-grid team-grid__second-section">
          <div className="members">
            {secondSection &&
              secondSection.members.map(
                (member: CardPageProps, index: number) => (
                  <div key={index} className="member">
                    <SliderItem
                      parent="team-grid"
                      index={index + 1}
                      uri={member.uri}
                      imgProps={member?.featuredImage?.node}
                      memberTag={member?.singleTeam?.position}
                      title={member.title}
                    />
                  </div>
                )
              )}
          </div>
          <div
            className="text-node"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            <h2>{secondSection?.text}</h2>
          </div>
        </TeamGrid>
        <TeamGrid sectionClass="team-grid team-grid__fourth-section">
          <>
            {thirdSection &&
              thirdSection.members.map(
                (member: CardPageProps, index: number) => (
                  <div key={index} className="member">
                    <SliderItem
                      parent="team-grid"
                      index={index + 1}
                      uri={member.uri}
                      imgProps={member?.featuredImage?.node}
                      memberTag={member?.singleTeam?.position}
                      title={member.title}
                    />
                  </div>
                )
              )}
          </>
          <div
            className="text-node"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            <span>{thirdSection?.number}</span>
            <h2>{thirdSection?.text}</h2>
          </div>
        </TeamGrid>
        <TeamGrid sectionClass="team-grid team-grid__third-section">
          <>
            {fourthSection &&
                fourthSection.members.map(
                    (member: CardPageProps, index: number) => (
                        <div key={index} className="member">
                          <SliderItem
                              parent="team-grid"
                              index={index + 1}
                              uri={member.uri}
                              imgProps={member?.featuredImage?.node}
                              memberTag={member?.singleTeam?.position}
                              title={member.title}
                          />
                        </div>
                    )
                )}
          </>
          <div
              className="text-node"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-easing="ease-out"
          >
            <span>{fourthSection?.number}</span>
            <h2>{fourthSection?.text}</h2>
          </div>
        </TeamGrid>
        <TeamGrid sectionClass="team-grid team-grid__fifth-section">
          <>
            {fifthSection &&
              fifthSection.map((member: CardPageProps, index: number) => (
                <div key={index} className="member">
                  <SliderItem
                    parent="team-grid"
                    index={index + 1}
                    uri={member.uri}
                    imgProps={member?.featuredImage?.node}
                    memberTag={member?.singleTeam?.position}
                    title={member.title}
                  />
                </div>
              ))}
            <div className="empty-member"></div>
          </>
        </TeamGrid>
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      teamOptions {
        teamMembers {
          firstSection {
            ... on WpTeam {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
              singleTeam {
                position
              }
            }
          }
          secondSection {
            text
            members {
              ... on WpTeam {
                id
                uri
                title
                featuredImage {
                  node {
                    altText
                    sizes
                    sourceUrl
                    srcSet
                  }
                }
                singleTeam {
                  position
                }
              }
            }
          }
          thirdSection {
            number
            text
            members {
              ... on WpTeam {
                id
                uri
                title
                featuredImage {
                  node {
                    altText
                    sizes
                    sourceUrl
                    srcSet
                  }
                }
                singleTeam {
                  position
                }
              }
            }
          }
          fourthSection {
            number
            text
            members {
              ... on WpTeam {
                id
                uri
                title
                featuredImage {
                  node {
                    altText
                    sizes
                    sourceUrl
                    srcSet
                  }
                }
                singleTeam {
                  position
                }
              }
            }
          }
          fifthSection {
            ... on WpTeam {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
              singleTeam {
                position
              }
            }
          }
        }
      }
    }
  }
`

export default Team
